import { style } from "./modaleStyle";
import closeButton from "./closeButton.svg";
import almaLogo from "./logo.svg";
import { MODAL_ID_PREFIX, URLS } from "../constants";
import { ENV, Translations } from "../types";
import { getCheckoutUrl, getVersion } from "../helpers";

let previousScrollPosition = 0;
let translations: Translations | null = null;

export function showModal(
  paymentId: string,
  env: ENV,
  newTranslations: Translations | null,
  userCloseModalCallback?: () => void
) {
  // Set translations loaded from Checkout:
  translations = newTranslations;

  // If a modal already exists, we want to replace it to display newest data.
  if (document.getElementById(`${MODAL_ID_PREFIX}-wrapper`)) {
    removeModal(false);
  }

  // This is a try to improve smoothness of scrolling inside the modal.
  // https://www.jayfreestone.com/writing/locking-body-scroll-ios/
  previousScrollPosition = window.scrollY;
  document.body.classList.add("alma-fixed-body");
  document.body.scroll(0, previousScrollPosition);

  const wrapper = createModalWrapperElement();
  const modalContainer = createModalContainerElement();
  const modalOverlay = createModalOverlayElement();
  const modalBody = createModalBodyElement();
  const modalClose = createModalCloseElement(userCloseModalCallback);
  const iframe = createIframeElement(paymentId, env);

  document.body.appendChild(wrapper);
  wrapper.appendChild(modalContainer);
  modalContainer.appendChild(modalOverlay);
  modalContainer.appendChild(modalBody);
  modalBody.appendChild(modalClose);
  modalBody.appendChild(iframe);
  wrapper.classList.add(`${MODAL_ID_PREFIX}-fadeIn`);
  modalBody.classList.add(`${MODAL_ID_PREFIX}-slideIn`);

  iframe.focus(); // 👈 Also related to scrolling in the modal.
}

export function removeModal(
  showConfirmation = true,
  userCloseModalCallback?: () => void
) {
  const modal = document.getElementById(`${MODAL_ID_PREFIX}-wrapper`);
  if (modal) {
    if (
      !showConfirmation ||
      confirm(
        translations?.closeModalMessage ||
          "Are you sure you want to leave the payment page?"
      )
    ) {
      const modalWrapper = document.getElementById(
        `${MODAL_ID_PREFIX}-wrapper`
      );
      const modalBody = document.getElementById(`${MODAL_ID_PREFIX}-body`);
      modalWrapper?.classList.add(`${MODAL_ID_PREFIX}-fadeOut`);
      modalBody?.classList.add(`${MODAL_ID_PREFIX}-slideOut`);

      window.setTimeout(() => {
        document.body.classList.remove("alma-fixed-body");
        document.body.scroll(0, previousScrollPosition);

        modal.remove();
        if (userCloseModalCallback) {
          userCloseModalCallback();
        }
      }, 300);
    }
  }
}

function createModalWrapperElement() {
  const element = document.createElement("style");
  element.innerHTML = style;
  // We create a wrapper to delete the style tag in the DOM easily
  const wrapper = document.createElement("div");
  wrapper.id = `${MODAL_ID_PREFIX}-wrapper`;
  wrapper.role = "dialog";
  wrapper.ariaModal = "true";
  wrapper.appendChild(element);

  return wrapper;
}

function createModalContainerElement() {
  const element = document.createElement("div");
  element.id = `${MODAL_ID_PREFIX}-element`;

  return element;
}

function createModalOverlayElement() {
  const element = document.createElement("div");
  element.id = `${MODAL_ID_PREFIX}-background`;
  return element;
}

function createModalBodyElement() {
  const element = document.createElement("div");
  element.id = `${MODAL_ID_PREFIX}-body`;

  return element;
}

function createIframeElement(paymentId: string, env: ENV) {
  const urlObject = new URL(getCheckoutUrl(paymentId, env, URLS.modal));
  urlObject.searchParams.append("inPageVersion", getVersion());

  const element = document.createElement("iframe");
  element.id = `${MODAL_ID_PREFIX}-iframe`;
  element.allow = "camera *;";
  element.src = urlObject.toString();
  element.title = "Alma payment iframe";

  return element;
}

function createModalCloseElement(userCloseModalCallback?: () => void) {
  const element = document.createElement("img");
  element.id = `${MODAL_ID_PREFIX}-close`;
  element.title =
    translations?.closeModalButtonHover ||
    "Close the alma modal (you'll lose your data)";
  element.onclick = () => removeModal(true, userCloseModalCallback);
  element.onkeyup = (event) => {
    if (event.key === "Enter") {
      removeModal(false, userCloseModalCallback);
    }
  };

  element.src = closeButton;

  element.tabIndex = 0;
  return element;
}

export function removeModalCloseElement() {
  const element = document.getElementById(`${MODAL_ID_PREFIX}-close`);
  element?.remove();
}

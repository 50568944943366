import { createStore } from "./store";
import { InitializeOptions, StartPaymentOptions } from "./types";

import { startListener } from "./messages/listener";
import { sendWaitingMessages } from "./messages/send";
import { mount } from "./mount";
import { startPayment } from "./startPayment";
import { unmount } from "./unmount";

/**
 * Initialize the InPage component
 * This methods displays the embedded Iframe and listen to messages from it
 *
 * @param options: InitializeOptions
 */
export function initialize(options: InitializeOptions) {
  const store = createStore(options.environment);
  const { onInPageStatusChanged, unsubscribe } = startListener(
    store.getEnvironment(),
    options.selector,
    store.setTranslations
  );

  /**
   * Listener for the "embedded_loaded" status
   * This status is triggered when the Embedded component is loaded
   * This triggers the messages queued while the Embedded component was loading
   */
  onInPageStatusChanged("embedded_loaded", "initialize", () => {
    store.setIsCheckoutLoaded(true);
    sendWaitingMessages(store);
  });

  /**
   * Listener for the "ready_for_payment" status
   * This status is triggered when the user clicks on the "Pay" button which is inside the Embedded component
   * This can be added through **onIntegratedPayButtonClicked**
   */
  onInPageStatusChanged("ready_for_payment", "initialize", () => {
    options.onIntegratedPayButtonClicked?.();
  });

  const mounted = mount(options);

  if (mounted) {
    store.setEmbeddedSelector(options.selector); // For the unmount method
  }

  return {
    startPayment: (options: StartPaymentOptions) =>
      startPayment(options, store, onInPageStatusChanged),
    unmount: () => unmount(store, unsubscribe),
  };
}

import { removeModal, removeModalCloseElement, showModal } from "./Modal/modal";
import { fetchReturnUrls } from "./helpers";
import { sendMessage } from "./messages/send";
import { Store } from "./store";
import { MessageType, StartPaymentOptions } from "./types";

type onInPageStatusChangedType = (
  eventName: MessageType,
  callerName: string,
  callback: () => void
) => void;

export function startPayment(
  options: StartPaymentOptions,
  store: Store,
  onInPageStatusChanged: onInPageStatusChangedType
) {
  const { paymentId, onPaymentRejected, onPaymentSucceeded, onUserCloseModal } =
    options;
  // Send a message to checkout that the customer is ready to pay
  sendMessage(store, { from: "in-page", type: "user_wants_to_pay" });

  const urls = {
    success: null,
    failure: null,
  };

  fetchReturnUrls(paymentId, store.getEnvironment()).then((returnUrls) => {
    urls.success = returnUrls.success;
    urls.failure = returnUrls.failure;
  });

  onInPageStatusChanged("can_open_modal", "start-payment", () => {
    showModal(
      paymentId,
      store.getEnvironment(),
      store.getTranslations(),
      onUserCloseModal
    );
  });

  onInPageStatusChanged(
    "payment_succeeded",
    "start-payment",
    removeModalCloseElement
  );
  onInPageStatusChanged(
    "payment_rejected",
    "start-payment",
    removeModalCloseElement
  );

  onInPageStatusChanged("trigger_success_callback", "start-payment", () => {
    handleTriggerCallback(onPaymentSucceeded, urls.success);
  });

  onInPageStatusChanged("trigger_reject_callback", "start-payment", () => {
    handleTriggerCallback(onPaymentRejected, urls.failure);
  });
}

function handleTriggerCallback(
  callback: (() => void) | undefined,
  url: string | null
) {
  removeModal(false);

  if (callback) {
    callback();
  } else if (url) {
    window.location.assign(url);
  }
}

import { URLS } from "./constants";
import { ENV } from "./types";
import version from "../version.json";

/**
 * Get the element from the DOM, if none is found, throw an error.
 *
 * @param selector string of the current element
 * @returns HTMLElement | throw exception
 */
export const getElement = (selector: string) => {
  const element = document.getElementById(selector.replace("#", ""));
  if (element) {
    return element;
  } else {
    elementNotFound();
  }
};

/**
 * Throws an error if the element is not found.
 */
export function elementNotFound() {
  throw new Error(
    "Element not found, please add an id selector for the iframe"
  );
}

export function getCheckoutUrlBasedOnEnv(env: ENV) {
  switch (env) {
    case "LOCAL":
      return "https://localhost:3000";
    case "TEST":
    case "SANDBOX":
      return "https://checkout.sandbox.getalma.eu";
    case "STAGING":
      return "https://checkout.staging.almapay.com";
    case "DEV":
      return "https://checkout.dev.almapay.com";
    case "LIVE":
    case "PROD":
    default:
      return "https://checkout.getalma.eu";
  }
}

function getAlmaAPIUrlBasedOnEnv(env: ENV) {
  switch (env) {
    case "LOCAL":
      return "http://localhost:1337";
    case "TEST":
    case "SANDBOX":
      return "https://api.sandbox.getalma.eu";
    case "STAGING":
      return "https://api.staging.almapay.com";
    case "DEV":
      return "https://api.dev.almapay.com";
    case "LIVE":
    case "PROD":
    default:
      return "https://api.getalma.eu";
  }
}

export function fetchReturnUrls(paymentId: string, env: ENV) {
  return fetch(getAlmaAPIUrlBasedOnEnv(env) + "/v1/payments/" + paymentId)
    .then((response) => response.json())
    .then((data) => {
      // To avoid bad integration, and possibly reduce the number of support requests
      if (env !== "PROD" && data.origin !== "online_in_page") {
        alert(
          'Your payment does not have the proper origin for an in-page payment, you should use "online_in_page". \n\nPlease, check the documentation:\nhttps://docs.almapay.com/docs/integration-in-page'
        );
      }
      return { success: data.return_url, failure: data.failure_return_url };
    });
}

export function getCheckoutUrl(paymentId: string, env: ENV, url: string) {
  return getCheckoutUrlBasedOnEnv(env) + "/" + paymentId + url;
}

export function getEligibilityCheckoutUrl(env: ENV) {
  return getCheckoutUrlBasedOnEnv(env) + URLS.embedded;
}

export function getVersion() {
  const match = version.match(/\d+\.\d+\.\d+/);
  if (match) {
    return match[0];
  }

  return "2.0.0";
}
